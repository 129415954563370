import React, { useEffect, useState } from 'react';
import { Card, CardContent, CardMedia, Box, CardActionArea, Chip, Typography, Skeleton } from '@mui/material';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import WatchLaterIcon from '@mui/icons-material/WatchLater';
import LocalGasStationIcon from '@mui/icons-material/LocalGasStation';
import config from '../../core/config';
import CarDemo from '../../assets/images/car-wash.png';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import LazyLoadImage from '../../components/LazyLoadImage';
import { height } from '@mui/system';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';

const CarTile = ({data = {}, onpress = () => {},  ...rest}) => {
    const [img, setImg] =  useState(CarDemo);
    const [values, setValues] = useState({});
    useEffect(()=>{
      let imgTimeout = setTimeout(() => {
        setImg((data.images.length > 0 &&  data.images[0].image) ? `${config.uris.PUBLIC_IMAGE_URI}/${data.images[0].image}` : CarDemo);
      }, 1000);

      let box = document.querySelector('.mycard');
      let width = box.offsetWidth;
      let height = box.offsetHeight;
      setValues({height: height, width: width});
      return ()=>{
        clearTimeout(imgTimeout)
      }
    }, []);

    const getFuleType = (val) => {
      if(val == 1){
        return "Petrol";
      }else if(val == 2) {
        return "Diesel";
      } else {
        return "CNG";
      }
    }

    return (
      <>
        <Card onClick={onpress}
            className={`mycard ${(data.is_booked > 0) && `mycardBooked`} ${(data.is_upcoming > 0) && `mycardUpcoming`}`}
            sx={{
              display: 'flex',
              borderRadius: "4px",
              boxShadow: "0 1px 5px 0 #00000042",
            }}
          >
            {/* {
              (data.is_booked > 0) && <h4 className='bookedText'>BOOKED</h4>
            } */}
              <CardMedia
                  component="img"
                  sx={{ 
                    width: "129px", 
                    height:"130px", 
                    filter : `${(data.is_booked > 0) ? 'contrast(0.5)' : ''}`,     
                  }}
                  image={img}
                  alt="Live from astergo"
              /> 
            <CardActionArea sx={
                  data.is_booked && data.is_booked > 0 ? 
                { backdropFilter : `${(data.is_booked > 0 || data.is_upcoming > 0) ? 'contrast(0.3)' : ''}` }
                : data.is_upcoming && data.is_upcoming > 0 ? { backdropFilter : `${(data.is_booked > 0 || data.is_upcoming > 0) ? 'contrast(0.3)' : ''}`, backgroundColor: "#bbffbc", filter: 'hue-rotate(348deg)' } 
                : {}
              }>
              <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: "space-between" }}>
                <CardContent sx={{ padding: "8px 8px !important" }}>
                  <h1 className='aster-car-box-title' style={{color: config.colors.primary.main}}>
                    {data.name}
                  </h1>
                  <p className='aster-car-box-footer'>
                    ADDED ON :{data.added_on}
                  </p>
                    <div className='myPill' style={{justifyContent: "flex-start"}}>
                      <div><WatchLaterIcon sx={{fontSize:"15px", marginTop: "5px"}} />&nbsp;</div>
                      <div>Year - {data.year}</div>
                    </div>
                  <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                    <div className='myPill'>
                      <div><LocationOnIcon sx={{fontSize:"15px", marginTop: "5px"}} />&nbsp;</div>
                      <div>{data.vechile_location}</div>
                    </div>
                    <div className='myPill'>
                      <div><LocalGasStationIcon sx={{fontSize:"15px", marginTop: "5px"}} />&nbsp;</div>
                      <div>{getFuleType(data.fuel_type) ?? 'NA'}</div>
                    </div>
                    <div className='myPill'>
                      <div><RemoveRedEyeIcon sx={{fontSize:"15px", marginTop: "5px"}} />&nbsp;</div>
                      <div>{data.views.toString() ?? '0'}</div>
                    </div>
                  </Box>
                  <Box sx={{ display: 'flex', alignItems: 'center', pl: 0, pt: 0 }}>
                    <Typography variant='subtitle2' sx={{
                      fontSize: "16px",
                      fontWeight: "600",
                      color: config.colors.black
                    }}>{`₹`+data.price.toString()}</Typography>
                  </Box>
                </CardContent>
              </Box>
            </CardActionArea>
        </Card>
      </>
    )
};

export default CarTile;