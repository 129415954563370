import React from 'react'

function About() {
  return (
    <div style={{marginTop: "60px"}}>
        <div style={{padding: "25px"}}>
            <h1 style={{fontSize: "30px", fontWeight: "500"}}>About Us</h1>
            <br/>
            <p style={{fontSize: "20px"}}>
                <strong>WE, BN&nbsp;Motors</strong>, Situated At Ranchi Road Manguria, Purulia, West Bengal Are One of The Most Trusted Second Hand Car Dealer In The City. We Are Leading Second Hand Car Dealer Where You Can Buy A&nbsp;Used Car Of Any Brand. Our Mission Is To Bring Joy &amp; Delight Into Car Buying &amp; Ownership. It&rsquo;s As Simple As That. We Believe in The Quality &amp; Dependability Of Our Used Cars. Keeping In Mind The Customer&rsquo;s Expectations And Concerns Our Expert Team Gets a Right Car For You.
            </p>
        </div>
    </div>
  )
}

export default About