import { Typography } from '@mui/material';
import React from 'react'

function Tandc() {
    return (
        <div style={{ marginTop: "60px" }}>
            <iframe style={{
              border: "none",
              position: "absolute",
              width: "100%",
              height: "100%",
              top: "65px",  
            }} src='https://bnmotors.astergo.in/terms-and-conditions.html'></iframe>
        </div>
    )
}

export default Tandc;
