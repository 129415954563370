import logo from './logo.svg';
import './App.css';
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import DefaultAvatar from './assets/images/avatar.png';

import React, { Fragment, useEffect, useState } from 'react';
import { AppBar, Button, Grid, Box, IconButton, Toolbar, Typography, BottomNavigation, BottomNavigationAction, Tabs, Tab, Avatar, Drawer, List, ListItem, ListItemText, ListItemButton, Chip, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import MenuIcon from '@mui/icons-material/Menu';
import config from './core/config';
import FaceIcon from '@mui/icons-material/Face';
import AddToPhotosIcon from '@mui/icons-material/AddToPhotos';
import HomeIcon from '@mui/icons-material/Home';
import IconTile from './components/IconTile';
import ListItemIcon from '@mui/material/ListItemIcon';
import Home from './screens/Home';
import { Route, Switch, useHistory, useLocation } from 'react-router-dom';
import Login from './screens/Auth/Login';
import Register from './screens/Auth/Register';
import AuthRoute from './routes/AuthRoute';
import GuestRoute from './routes/GuestRoute';
import { useDispatch, useSelector } from 'react-redux';
import { Stack } from '@mui/system';
import WebFooter from './components/WebFooter';

import LoginIcon from '@mui/icons-material/Login';
import LogoutIcon from '@mui/icons-material/Logout';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import ViewListIcon from '@mui/icons-material/ViewList';
import Profile from './screens/Auth/Profile';


import ShareIcon from '@mui/icons-material/Share';
import GavelIcon from '@mui/icons-material/Gavel';



//redux
import { make_logout as makeLogout } from './store/actions/AuthAction';
import PostCar from './screens/Car/PostCar';
import Tandc from './screens/Tandc';
import About from './screens/About';
import axios from 'axios';
import Search from './screens/Search';

const App = () => {
  const location = useLocation();
  const history = useHistory();
  const authData = useSelector(state => state.auth);
  const [homeData, setHomeData] = useState(null);
  const [isOpenDrawer, setIsOpenDrawer] = useState(false);
  const [isOpenAlert, setIsOpenAlert] = useState(false);
  const [isWebsite, setIsWebsite] = useState(false);


  const handleAlertAction = () => {
    setIsOpenAlert(!isOpenAlert);
  }
  
  const handleDrawer = () => {
    setIsOpenDrawer(!isOpenDrawer);
  }

  const fetchHomData = async () => {
    await axios.get(`${config.rootUrl}/app-alert`)
    .then(({ data }) => {
        if (data.status) {
          setHomeData(data.data);
        }
    }).catch(err => {
        console.error(err);
    });
    
  }


  useEffect(() => {
    fetchHomData();
    if(window.screen.width> 1000) {
      console.log('Large Screen');
      setIsWebsite(true);
    } else{
      setIsWebsite(false);
    }
    console.log(authData);

  },[]);

  return (
    <div className="App">
      <AppBar position="fixed" elevation={1}>
        <Toolbar variant="regular" sx={{ padding: "4px 10px" }}>
          {
            authData.isAuth 
            &&
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{ mr: 2 }}
              onClick={handleDrawer}
            >
              <MenuIcon sx={{ fontSize: "35px" }} />
            </IconButton>
          }
          <Typography align={authData.isAuth ? 'left' : 'center'} variant="h5" component="div" sx={{ flexGrow: 1, fontWeight: "600", }}>
            BN MOTORS
          </Typography>
          {
            authData.isAuth 
            ? <Avatar alt="Remy Sharp" src={DefaultAvatar} />
            : <></>
            //<Button color="inherit" onClick={() => { history.push('login')}} >Login</Button>
           }
        </Toolbar>
      </AppBar>
      {
        homeData && 
        <div className='floating-alert' style={{top: (location.pathname == '/') ? "165px" : ""} }>
        {/* <div className='floating-alert' style={{top: (location.pathname == '/') ? "181px" : ""} }> */}
          <marquee><h2>{homeData.message}</h2></marquee>
        </div>
      }
      <Switch>
        <Route exact path="/" component={Home} />
        <Route path="/tandc" component={Tandc} />
        <Route path="/about" component={About} />
        <Route path="/search" component={Search} />
        <AuthRoute path="/profile" component={Profile} />
        <AuthRoute path="/post" component={PostCar} />

        <GuestRoute path='/login' component={Login} />
        <GuestRoute path='/register' component={Register} />
        {/* <GuestRoute path="/" render={(props) => { <Redirect to="login" /> }} /> */}
        
        {/* <GuestRoute path="/login" component={Login} /> */}
        {/* <Route path="/profile" component={Profile} /> */}
        {/* <Route exact path="/" component={Home} />
        <Route exact path="/login" component={Login} />
        <Route exact path="/register" component={Register} /> */}
      </Switch>
      {
        isWebsite && <WebFooter />
      }
      {/* <Home /> */}
      <MyBottomNav />
      {
        (authData.isAuth) &&
        <>
          <MyDrawer open={isOpenDrawer} onclose={handleDrawer} />
        </>
      }
      {/* <MyAlert open={isOpenAlert} onclose={handleAlertAction}/> */}
      
    </div>
  );
}

const MyDrawer = ({open = false, onclose = () => {}, ...rest}) => {

  return (
    <Drawer
      anchor="left"
      open={open}
      onClose={onclose}
    >
      <MyDrawerItems onclose={onclose} />
    </Drawer>
  );
}


const MyDrawerItems = ({onclose = () => {}, ...rest}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const authData = useSelector(state => state.auth);



  const handleLogout = () => {
    var text = "Are you sure, want to logout?"
    if (window.confirm(text) == true) {
      dispatch(makeLogout())
    }
    onclose()
  }

  const handleCommanRedirect = (path ='/') => {
    history.push(path);
    onclose();
  }  

  const AuthList = [
    {label: "Home", icon: <HomeIcon sx={{color: config.colors.black}} />, onpress : () => handleCommanRedirect('/'),},
    {label: "My Quotation", icon: <ViewListIcon sx={{color: config.colors.black}} />, onpress : () => alert("Get lost"),},
    {label: "Profile", icon: <FaceIcon sx={{color: config.colors.black}} />, onpress : () => { handleCommanRedirect('/profile'); },},
    {label: "Logout", icon: <LogoutIcon sx={{color: config.colors.black}} />, onpress : handleLogout,},
    {label: "Terms & Conditions", icon: <GavelIcon sx={{color: config.colors.black}} />, onpress : () => handleCommanRedirect('/tandc'),},
    // {label: "Share", icon: <ShareIcon sx={{color: config.colors.black}} />, onpress : (e) => {
    //   e.preventDefault();
    //   window.location.href='http://bnmotors.in';
    // },},
  ];

  const GuestList = [
    {label: "Login", icon: <LoginIcon sx={{color: config.colors.black}} />, onpress : () => handleCommanRedirect('/login'),},
    {label: "Register", icon: <PersonAddIcon sx={{color: config.colors.black}} />, onpress : () => handleCommanRedirect('/register'),},
  ];

  return (
    <Box sx={{width: "280px"}}>
      {
        authData.isAuth 
        &&
      <Fragment>
        <Stack 
            spacing={1.5} 
            direction="column" 
            justifyContent="center" 
            alignContent="center" 
            alignItems="center"
            sx={{
              background : config.colors.primary.main,
              color: "white",
              padding: "20px 0px"
            }}
          >
            <Avatar alt="satyajit-kumar" src={(authData.user.image != null) ? `${config.uris.IMAGE_URI}/${authData.user.image}` : DefaultAvatar}
              sx={{ width: "100px", height: "100px" }}
            />
            <Typography variant='h5' sx={{
              fontWeight: "600",
              fontSize: "20px"
            }}>
              { authData.user.name ?? '' }
            </Typography>
            <Chip 
            sx={{height: "22px"}}
            label={
              <p style={{color: "white", padding: "0px 10px", fontSize: "16px"}}>User</p>
            } color="secondary"  />
        </Stack>
      
        <List sx={{ width: '100%', bgcolor: 'background.paper' }} dense={true}>
          {   

              authData.isAuth 
              ? AuthList.map((item, i) => {
                return (
                  <ListItem
                    key={i}
                    disablePadding
                  >
                    <ListItemButton role={undefined} onClick={item.onpress} dense>
                      <ListItemIcon>
                        {item.icon}
                      </ListItemIcon>
                      <ListItemText primary={<p className='drawerItemTitle'>
                        {item.label}
                      </p>} />
                    </ListItemButton>
                  </ListItem>
                )
              }) 
              :GuestList.map((item, i) => {
                return (
                  <ListItem
                    key={i}
                    disablePadding
                  >
                    <ListItemButton role={undefined} onClick={item.onpress} dense>
                      <ListItemIcon>
                        {item.icon}
                      </ListItemIcon>
                      <ListItemText primary={<p className='drawerItemTitle'>
                        {item.label}
                      </p>} />
                    </ListItemButton>
                  </ListItem>
                )
              })
          }
        </List>
        <div style={{
          padding: "10px"
        }}>
          <p style={{
              fontSize: "12px",
              textAlign: "justify",
              color: "#000",
              fontWeight: "600",
              margin: "0px",
          }}>
            All opinions are your own and BN Motors is only medium to communicating, so take all actions at your own risk
          </p>
        </div>
        <div style={{
          position: "absolute",
          left: "0px",
          bottom: "0px",
          width: "100%"
        }}>
          <center>
            <p style={{color:"#7d7d7d", fontWeight: "500", lineHeight: "24px", fontSize: "12px"}}>Powered by 
              <br />
              <a href="https://astergo.in" target="_blank" style={{color: config.colors.primary.main, textDecoration:"none", fontWeight:"600",fontSize: "16px"}}>ASTERGO</a>
              </p>
          </center>
        </div>
      </Fragment>
      }
    </Box>
  );
}


const MyBottomNav = () => {
  const history = useHistory();
  const [value, setValue] = React.useState(0);
  return (
    <div className='bottomNav'>
        <Box sx={{}}>
          <BottomNavigation
            sx={{}}
            showLabels
            value={value}
            onChange={(event, newValue) => {
              // alert(newValue);
              setValue(newValue);
              if(newValue == 0) {
                history.push('/');

              }
              if(newValue == 1) {
                history.push('/post');

              }
              if(newValue == 2) {
                history.push('/profile');
              }
            }}
          >
            <BottomNavigationAction onClick={() => { history.push('/')}} sx={{padding:"10px", minWidth: "70px"}} label="Home" icon={<HomeIcon sx={{padding:"0px", fontSize: "25px", marginBottom: "5px"}} />} />
            {/* <BottomNavigationAction sx={{padding:"10px", minWidth: "70px"}} label="Wishlist" icon={<FavoriteIcon sx={{padding:"0px", fontSize: "30px", marginBottom: "5px"}} />} /> */}
            <BottomNavigationAction sx={{padding:"10px", minWidth: "70px"}} label="Post" icon={<AddToPhotosIcon sx={{padding:"0px", fontSize: "25px", marginBottom: "5px"}} />} />
            {/* <BottomNavigationAction sx={{padding:"10px", minWidth: "70px"}} label="Favorites" icon={<FavoriteIcon sx={{padding:"0px", fontSize: "30px", marginBottom: "5px"}} />} /> */}
            <BottomNavigationAction sx={{padding:"10px", minWidth: "70px"}} label="Profile" icon={<FaceIcon sx={{padding:"0px", fontSize: "25px", marginBottom: "5px"}} />} />
          </BottomNavigation>
        </Box>
      </div>
  );
}

const MyAlert = ({open = false, onclose = () => {}, ...rest}) => {
  return (
    <Dialog
        open={open}
        onClose={onclose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Use Google's location service?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure want to logout!
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onclose}>Cancle</Button>
          <Button variant='text' color={config.colors.red} onClick={()=> alert('Logout Success')} autoFocus>
            Logout
          </Button>
        </DialogActions>
    </Dialog>
  );
}

export default App;
