import React from 'react';
import Row from '../../components/Row';
import Col from '../../components/Column';
import MyCard from '../../components/MyCard';
import { Grid, Typography } from '@mui/material';
import config from '../../core/config';

const CarSpec = ({ title = '', subtitle = "", icon = null, type = "regular", isIcon = true, ...rest }) => {
  if (type == 'simple') {
    return (
      <div>
        <Grid container>
          <Grid lg={6} md={6} sm={6} xs={6}>
            <Row style={{ justifyContent: "flex-start" }}>
              {(isIcon) && icon}
              <Typography variant="subtitle2" color="primary" sx={{ fontSize: "15px", fontWeight: "500", marginLeft: "10px", marginBottom: "-5px" }}>{subtitle ?? ''}</Typography>
            </Row>
          </Grid>
          <Grid lg={6} md={6} sm={6} xs={6}>
            <Typography color="black" variant='h6' sx={{ fontSize: "15px", fontWeight: "600", marginBottom: "-5px" }}>:&nbsp;&nbsp;{title ?? ''}</Typography>
          </Grid>
        </Grid>
      </div>
    )
  } else {
    return (
      <MyCard style={{
        background: config.colors.accent.light,
        border: `1px solid ${config.colors.accent.main}`
      }} padding="5px 0px">
        <Row style={{
          alignContent: "center",
          alignItems: "center",
          justifyContent: "flex-start",
        }}>
          <div style={{
            color: "#000",
            paddingLeft: "-15px",
            paddingRight: "10px",
            paddingTop: "5px"
          }}>
            {icon}
          </div>
          <div>
            <Col>
              <Typography color="black" variant='h6' sx={{ fontSize: "16px", fontWeight: "600", marginBottom: "-5px" }}>{title ?? ''}</Typography>
              <Typography variant="subtitle2" color="primary" sx={{ fontSize: "12px", fontWeight: "400", marginBottom: "-5px" }}>{subtitle ?? ''}</Typography>
            </Col>
          </div>
        </Row>
      </MyCard>
    )
  }
};

export default CarSpec;