import React, { useState } from 'react';
import { Box, FormControl, InputLabel, OutlinedInput, TextField, InputAdornment, IconButton, Card, CardContent, Grid, Typography, Button, FormHelperText } from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Logo from '../../assets/images/logo.jpeg';
import config from '../../core/config';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux'
import { set_login as setLogin } from '../../store/actions/AuthAction';
import axios from 'axios';

function Login() {

    const [values, setValues] = React.useState({
        password: '',
        showPassword: false,
    });
    const [passwordErr, setPasswordErr] = useState(false);

    const history = useHistory();
    const dispatch = useDispatch();

    const [isSendOtp, setIsSendOtp] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [phone, setPhone] = useState('');
    const [userId, setUserId] = useState(null);
    const [phoneErr, setPhoneErr] = useState(false);
    const [otp, setOtp] = useState('');
    const [otpErr, setOtpErr] = useState(false);

    const handleSendOtp = async() => {
        if(phone == '' && phone.length < 10 ) {
            setPhoneErr(true);
        // } else if(values.password == '')  {
        //     setPasswordErr(true);
        } else {
            setPhoneErr(false);
            setIsLoading(true);
            const data = {
                phone: phone,
                // email: phone,
                // password :values.password
            };
            // alert("Here!");
            // await axios.post(`${config.rootUrl}/${config.uris.LOGIN_URI}`, data)
            await axios.post(`${config.rootUrl}/${config.uris.OTP_LOGIN_URI}`, data)
            .then(({data}) => {
                // console.log(data);
                if (data.status) {
                    setUserId(data.user_id);
                    setIsSendOtp(true);
                    setIsLoading(false);

                    // localStorage.setItem('token', data.token);
                    // dispatch(setLogin(data.data.user))
                    // setIsLoading(false);
                    // history.push('/');

                } else {
                    alert(data.message);
                    setIsLoading(false);
                }
            })
            .catch(err => {
                console.error(err);
            });
        }
    };

    const handleOtpLogin = async (event) => {
        event.preventDefault();
        if(otp != '' && otp.length >= 4) {
            setOtpErr(false);
            setIsLoading(true);

            const data = {
                code: otp,
                user_id: userId
            }

            await axios.post(`${config.rootUrl}/${config.uris.TWO_FACTOR_AUTH}`, data)
            .then(({data}) => {
                if (data.status) {
                    // console.log(data);
                    localStorage.setItem('token', data.token);
                    dispatch(setLogin(data.data.user))
                    setIsLoading(false);
                    history.push('/');
                } else {
                    alert(data.message);
                    setIsLoading(false);
                }
            })
            .catch(err => {
                console.error(err);
            });
        } else {
            setOtpErr(true);
        }
    }

    const handleClickShowPassword = () => {
        setValues({
            ...values,
            showPassword: !values.showPassword,
        });
    };
    
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    return (
        <div style={{marginTop: "30px"}}>
            <div style={{padding: "15% 15px"}}>
                <Grid container spacing={0.2} justifyContent="center" alignContent="center" alignItems="center">
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                        <div style={{
                        }}>
                            <center>
                                <img src={Logo} width="100px" style={{
                                    boxShadow: "0 0 20px 0 #03a9f47a",
                                    borderRadius: "100%",
                                    marginBottom: "10px",
                                }} />
                                {/* <Typography variant="h5" sx={{color: config.colors.primary.main, fontWeight: "600"}}>
                                    BN MOTORS
                                </Typography> */}
                            </center>
                        </div>
                    </Grid>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                        <Card sx={{marginBottom: "8px"}}>
                            <CardContent>
                                <Typography variant='h5'>Login</Typography>
                                { (!isSendOtp) ?
                                <Box autoComplete="off">
                                        <FormControl sx={{margin: "15px 0px"}} fullWidth >
                                            <TextField id="outlined-basic" label="Phone Number" variant="outlined"
                                                onChange={(e) => setPhone(e.target.value)}
                                                inputProps={{ inputMode: 'numeric', pattern: '[0-9]*', maxLength: "10", minLength: "10" }}
                                                error={phoneErr}
                                                value={phone}
                                            />
                                            {
                                                (phoneErr) 
                                                &&
                                                <FormHelperText sx={{color: config.colors.red}} id="phone-helper">Invalid phone number</FormHelperText>
                                            }
                                        </FormControl>
                                        {/* <FormControl sx={{margin: "15px 0px"}} fullWidth variant="outlined">
                                            <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                                            <OutlinedInput
                                                id="outlined-adornment-password"
                                                type={values.showPassword ? 'text' : 'password'}
                                                value={values.password}
                                                onChange={(e) => setValues({...values, password: e.target.value})}
                                                endAdornment={
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={handleClickShowPassword}
                                                        onMouseDown={handleMouseDownPassword}
                                                        edge="end"
                                                        >
                                                        {values.showPassword ? <VisibilityOff /> : <Visibility />}
                                                        </IconButton>
                                                    </InputAdornment>
                                                }
                                                label="Password"
                                            />
                                            {
                                                (passwordErr) 
                                                &&
                                                <FormHelperText sx={{color: config.colors.red}} id="phone-helper">Password Required</FormHelperText>
                                            }
                                        </FormControl> */}
                                        <FormControl sx={{margin: "15px 0px 0px 0px"}} fullWidth >
                                            {
                                                (isLoading) 
                                                ?
                                                <LoadingButton loading variant="outlined">
                                                    Login
                                                </LoadingButton>
                                                :
                                                <Button variant='contained' color="primary"
                                                    onClick={handleSendOtp}
                                                >Login</Button>
                                            }
                                        </FormControl>
                                </Box>
                                : 
                                <Box autoComplete="off" >
                                        <FormControl sx={{margin: "15px 0px"}} fullWidth >
                                            <TextField id="outlined-basic" label="Enter OTP" variant="outlined"
                                            inputProps={{ inputMode: 'numeric', pattern: '[0-9]*', maxLength: "6", minLength: "4" }}
                                            value={otp}
                                            onChange={(e) => setOtp(e.target.value)}
                                            error={otpErr}
                                            />
                                            {
                                                (otpErr) 
                                                &&
                                                <FormHelperText sx={{color: config.colors.red}} id="phone-helper">Invalid OTP</FormHelperText>
                                            }
                                        </FormControl>
                                        
                                        <FormControl sx={{margin: "15px 0px"}} fullWidth >
                                            {
                                                (isLoading) 
                                                ?
                                                <LoadingButton loading variant="outlined">
                                                    Sending...
                                                </LoadingButton>
                                                :
                                                <Button variant='contained' color="primary" onClick={handleOtpLogin}>Send</Button>
                                            }
                                        </FormControl>
                                </Box>
                                }
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item lg={12}>
                        <Button variant="text" onClick={() => history.push('register')}>Register Here</Button>   
                    </Grid>
                </Grid>
            </div>
        </div>
    )
}

export default Login
