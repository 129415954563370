import React, { useState, Fragment } from "react";
import { Tabs, Tab, Button, Grid, Box, Typography, List, ListItem, ListItemAvatar, Avatar, ListItemText, Divider, Rating, Modal, ListItemButton, FormHelperText } from '@mui/material';
import Column from "../components/Column";
import CarTile from "./Car/CarTile";
import axios from "axios";
import SearchIcon from "@mui/icons-material/Search";
import config from "../core/config";
import Row from "../components/Row";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from 'react-redux';
import { LoadingButton } from '@mui/lab';

import CarSpec from '../screens/Car/CarSpec';
import SidePanel from '../components/SlidePanel';
import CarCarousel from '../screens/Car/CarCarousel';

// spec
import LocationOnIcon from '@mui/icons-material/LocationOn';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import FitnessCenterIcon from '@mui/icons-material/FitnessCenter';
import PriceCheckIcon from '@mui/icons-material/PriceCheck';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import TimeToLeaveIcon from '@mui/icons-material/TimeToLeave';
import PersonPinCircleIcon from '@mui/icons-material/PersonPinCircle';
import CreditScoreIcon from '@mui/icons-material/CreditScore';
import HandymanIcon from '@mui/icons-material/Handyman';
import Person4Icon from '@mui/icons-material/Person4';
import PhoneInTalkIcon from '@mui/icons-material/PhoneInTalk';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

const Search = () => {

	const history = useHistory();
	const authData = useSelector(state => state.auth);

	const [visibleItemTile, setVisibleItemTile] = useState(false);
	const [isLoading , setIsLoading] = useState(false);   
	const [searchText , setSearchText] = useState("");   
  const [searchArray, setSearchArray] = useState([]);
	const [selectedProduct, setSelectedProduct] = useState(null);
	const [callModalOpen, setCallModalOpen] = React.useState(false);
	const [wpModalOpen, setWpModalOpen] = React.useState(false);
	const [priceModalOpen, setPriceModalOpen] = React.useState(false);

  const fetchResults = async()=>{
		if(searchText.length > 2){	
			setIsLoading(true);
			axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
			await axios.post(`${config.rootUrl}/${config.uris.search}`,{name : searchText})
					.then((res) => {
							if (res.data.status) {
									console.log('====================================');
									console.log("Saerch Data",res.data);
									console.log('====================================');
									setSearchArray(res.data.data)
									setIsLoading(false)
							} else {
									console.log(res);
									setIsLoading(false);
							}
					}).catch(err => {
							console.error(err);
							setIsLoading(false);
					});
		}else{
			setSearchArray([])
		}
  }


	const increaseViews = async(id = null) => {
		var myUri = '';
		if(authData.isAuth){
				var token = localStorage.getItem('token');
				axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
				myUri = `${config.rootUrl}/auth/${config.uris.triggreViews}`;
		} else {
				myUri = `${config.rootUrl}/${config.uris.triggreViews}`;

		}
		await axios.post(myUri, {
			 product_id: id 
		})
		.then(({ data }) => {
				if (data.status) {
						console.log(data);
				} 
		}).catch(err => {
				console.error(err);
		});
}

  // useEffect(()=>{
	// 	fetchResults()
  // },[searchText])


		const handleCallModalOpen = () => setCallModalOpen(true);
    const handleCallModalClose = () => setCallModalOpen(false);

    const handleWpModalOpen = () => setWpModalOpen(true);
    const handleWpModalClose = () => setWpModalOpen(false);
    
    const handlePriceModalOpen = () => {
        authData.isAuth 
        ? setPriceModalOpen(true)
        : history.push('/login');
    };
    const handlePriceModalClose = () => setPriceModalOpen(false);

		const handleSidePanelClose = () => {
				setSelectedProduct(null);
				setVisibleItemTile(false);
		}


	const carSpecsList = [
		{ title: selectedProduct != null && selectedProduct.registration_authority, subtitle: "Regstration Authority", icon: <PersonPinCircleIcon sx={{ color: config.colors.primary.main, fontSize: "14px" }} /> },
		{ title: selectedProduct != null && selectedProduct.manufacture_date, subtitle: "Manufacturer Date", icon: <HandymanIcon sx={{ color: config.colors.primary.main, fontSize: "14px" }} /> },
		{ title: selectedProduct != null && selectedProduct.vechile_location, subtitle: "Vechile Location", icon: <LocationOnIcon sx={{ color: config.colors.primary.main, fontSize: "14px" }} /> },
		{ title: selectedProduct != null && selectedProduct.insurance, subtitle: "Insurance", icon: <CreditScoreIcon sx={{ color: config.colors.primary.main, fontSize: "14px" }} /> },
		{ title: selectedProduct != null && selectedProduct.kilometre_drive, subtitle: "KM Drive", icon: <TimeToLeaveIcon sx={{ color: config.colors.primary.main, fontSize: "14px" }} /> },
		{ title: selectedProduct != null && selectedProduct.fitness, subtitle: "Fitness", icon: <FitnessCenterIcon sx={{ color: config.colors.primary.main, fontSize: "14px" }} /> },
		{ title: selectedProduct != null && selectedProduct.rc_or_ca, subtitle: "RC/CA", icon: <HandymanIcon sx={{ color: config.colors.primary.main, fontSize: "14px" }} /> },
		{ title: selectedProduct != null && selectedProduct.year, subtitle: "Year", icon: <AccessTimeIcon sx={{ color: config.colors.primary.main, fontSize: "14px" }} /> },
		{ title: selectedProduct != null && selectedProduct.tax, subtitle: "Tax", icon: <PriceCheckIcon sx={{ color: config.colors.primary.main, fontSize: "14px" }} /> },
		{ title: selectedProduct != null && selectedProduct.hp, subtitle: "HP", icon: <PriceCheckIcon sx={{ color: config.colors.primary.main, fontSize: "14px" }} /> },
];


  return (
    <>
      <div
        style={{
          marginTop: "63px",
          position: "fixed",
          top: "2px",
          width: "100%",
          zIndex : 1000
        }}
      >
        <div
          style={{
            // background: "linear-gradient(180deg, rgb(0, 31, 111), #003e83, rgb(0, 165, 237))",
            background: config.colors.primary.main,
            // height: "180px",
            // borderBottomLeftRadius: "20px",
            // borderBottomRightRadius: "20px",
          }}
        >
          <Column>
            <Grid container justifyContent="center" spacing={0}>
              <Grid item xs={10} sm={10} md={4} lg={4}>
                <input
                  onChange={(e) => {
                    setSearchText(e.target.value);
                  }}
                  value={searchText}
                  type="text"
                  className="form-control app-input"
                  placeholder="Search ..."
                />
              </Grid>
              <Grid item xs={2} sm={2} md={1} lg={1}>
                <Button
									onClick={()=>fetchResults()}
                  size="large"
                  variant="contained"
                  color="secondary"
                  sx={{
                    marginLeft: "-2px",
                    marginTop: "5px",
                    width: "98%",
                    minWidth: "57px",
                    height: "40px",
                    color: "#fff",
                    borderRadius: "12px",
                  }}
                >
                  <SearchIcon />
                </Button>
              </Grid>
            </Grid>
          </Column>
          
        </div>
      </div>
      <div id="popularCars" style={{marginTop : '120px',paddingTop : '50px'}}>
        <Grid
          container
          spacing={0.9}
          style={{
            background: "white",
            borderRadius: 10,
          }}
        >
					<Grid style={{width : '100%'}}>
						{searchText.length < 3  && <p style={{textAlign : 'center'}}>Seach keyword at least 3 Words</p>}
						{isLoading && <p style={{textAlign : 'center'}}>Loading...</p>}
					</Grid>
						{searchArray.map((item, i) => (
            <Grid key={i} item xs={12} sm={12} md={4} lg={3}>
                <br />
              <CarTile
                    data={item}
                    onpress={() => {
                        setSelectedProduct(item);
                        setVisibleItemTile(true);
                        increaseViews(item.id);
                        // handleItemTileAction(item)
                    }}
                    />
            </Grid>
          ))}
        </Grid>
      </div>
			<SidePanel title={selectedProduct != null && selectedProduct.name} visible={visibleItemTile} onclose={handleSidePanelClose}>
				{
						selectedProduct != null 
						&&
						<>
								<div>
										<CarCarousel images={selectedProduct.images} />
								</div>
								<div className='px-15'>
										<Grid container spacing={0.5}>
												<Grid item xs={12} sm={12}>
														<Typography variant='h3' color={config.colors.primary.main} sx={{ fontSize: "25px", fontWeight: "600" }}>{selectedProduct.name}</Typography>
														<p style={{color:"#333333", fontWeight:"600", fontSize:"13px", marginTop: "4px"}}>Added No: {selectedProduct.added_on}</p>
												</Grid>
												<Grid item xs={6} sm={6}>
														<Typography variant='h6' color={config.colors.black} sx={{ fontSize: "19px", fontWeight: "600" }}>Specifications</Typography>
														<br />
												</Grid>
												<Grid item xs={6} sm={6}>
														{/* <div style={{
																display: "flex",
																flexDirection: "row",
																flexWrap: "nowrap",
																alignContent: "center",
																justifyContent: "flex-end",
														}}>
																<span className='rating-box'>{(parseFloat(selectedProduct.rating).toFixed(1) == "NaN") ? `0.0` : parseFloat(selectedProduct.rating).toFixed(1)}</span>
																<Rating SX={{ mt: 1 }}
																		name="simple-controlled"
																		value={selectedProduct.rating}
																		onChange={(event, newValue) => {
																				// alert(newValue);
																				setRating(newValue);
																		}}
																/>
														</div> */}
												</Grid>
												{
														carSpecsList.map((item, i) => {
																return (
																		<Grid key={i} item xs={12} sm={12}>
																				<CarSpec type="simple" title={item.title} subtitle={item.subtitle} icon={item.icon} isIcon={true} />
																		</Grid>
																)
														})
												}

												<Grid item xs={12} sm={12}>
														<br />
														<Typography variant='h6' color={config.colors.black} sx={{ fontSize: "19px", fontWeight: "600" }}>Description</Typography>
														<p style={{fontSize:"14px", color: "#333333"}}>{selectedProduct.description}</p>
												</Grid>
												<Grid item xs={12} sm={12}>
														<br />
														{/* <Typography variant='h6' color={config.colors.black} sx={{ fontSize: "16px", fontWeight: "600" }}>Saler Details</Typography> */}
														<Grid container spacing={1} justifyContent='center'>
																<Grid item lg={4} md={4} sm={12} xs={12}>
																		<center>
																				<Button variant="contained" color="secondary" sx={{borderRadius: "30px", width: "100%"}} onClick={handleCallModalOpen}>Contact Saler</Button>
																		</center>
																</Grid>
																<Grid item lg={4} md={4} sm={12} xs={12}>
																		<center>
																				<Button variant="contained" sx={{borderRadius: "30px", background: "#008069", width: "100%"}} onClick={handleWpModalOpen}>WhatsApp</Button>
																		</center>
																</Grid>
														</Grid>
														<br />
												</Grid>
										</Grid>
										<div style={{
												marginTop: "20px",
												background: config.colors.accent.light,
												borderRadius: "44px",
												padding: "12px 1px",
										}}>
												<Grid container spacing={0} justifyContent="space-around" alignItems="center" alignContent="space-between">
														<Grid item xs={6} sm={6} lg={4} md={4}>
																<p style={{
																		fontSize: "25px",
																		fontWeight: "600",
																		color: config.colors.black,
																		margin: "12px 28px"
																}}>{selectedProduct.price}</p>
														</Grid>
														<Grid item xs={6} sm={6} lg={4} md={4}>
																<Button variant="contained" size='large'
																		onClick={handlePriceModalOpen}
																		sx={{ borderRadius: "50px", padding: "12px 22px", fontWeight: "600", textTransform: "capitalize" }}
																		startIcon={<CurrencyRupeeIcon sx={{

																		}} />}>Your Price</Button>
														</Grid>
												</Grid>
										</div>

										<br />
										<MyModalWp modalOpen={wpModalOpen} handleModalClose={handleWpModalClose} />
										<MyModalCall modalOpen={callModalOpen} handleModalClose={handleCallModalClose} />
										<MyModalPrice product={selectedProduct} modalOpen={priceModalOpen} handleModalClose={handlePriceModalClose} />
								</div>
						</>
				}
		</SidePanel>
    </>
  );
};


const MyModalWp = ({modalOpen = false, handleModalClose = () => {}, ...rest}) => {
    
	const contactLinksWp = [
			{ type: "whatsapp", label: "+91 7710 222 444", number: "+917710222444" },
			{ type: "whatsapp", label: "+91 7710 222 666", number: "+917710222666" },
			{ type: "whatsapp", label: "+91 7710 177 177", number: "+917710177177" },
			{ type: "whatsapp", label: "+91 9732 777 888", number: "+919732777888" },
	];
	
	return (
			<Modal
					open={modalOpen}
					onClose={handleModalClose}
					aria-labelledby="modal-modal-title"
					aria-describedby="modal-modal-description"
			>
					<Box className="budget-modal">
							<ContactOption links={contactLinksWp} />
					</Box>
			</Modal>
	)
}

const MyModalCall = ({modalOpen = false, handleModalClose = () => {}, ...rest}) => {
	const contactLinksCall = [
			{ type: "call", label: "+91-7710 222 444", number: "+917710222444" },
			{ type: "call", label: "+91-7710 222 666", number: "+917710222666" },
			{ type: "call", label: "+91-7710 177 177", number: "+917710177177" },
			{ type: "call", label: "+91-9732 777 888", number: "+919732777888" },
	];
	
	
	return (
			<Modal
					open={modalOpen}
					onClose={handleModalClose}
					aria-labelledby="modal-modal-title"
					aria-describedby="modal-modal-description"
			>
					<Box className="budget-modal">
							<ContactOption links={contactLinksCall} />
					</Box>
			</Modal>
	)
}

const MyModalPrice = ({product = {}, modalOpen = false, handleModalClose = () => {}, ...rest}) => {
	
	return (
			<Modal
					open={modalOpen}
					onClose={handleModalClose}
					aria-labelledby="modal-modal-title"
					aria-describedby="modal-modal-description"
			>
					<Box className="budget-modal">
							<SendPrice product={product} handleModalClose={handleModalClose} />
					</Box>
			</Modal>
	)
}

const ContactOption = ({links = [], ...rest}) => {
	return (
			<>
					<Typography variant="h5">
							Contact
					</Typography>
					<List dense={true}>
					{
							links.map((item, i) => {
									return (item.type == 'call')
											? (
													<Fragment key={i}>
															<Divider />
															<ListItem
																	sx={{ padding: "0px 0px" }}
															>
																	<ListItemButton
																			sx={{ padding: "4px 0px" }}
																			onClick={() => {
																					window.open(`tel:${item.number}`, '_self');
																			}}
																	>
																			<ListItemAvatar>
																					<Avatar sx={{ background: config.colors.accent.light }}>
																							<PhoneInTalkIcon color="primary" />
																					</Avatar>
																			</ListItemAvatar>
																			<ListItemText primary={<Typography variant="subtitle2" color={config.colors.black}>
																					<b>{item.label} </b>
																			</Typography>} />
																	</ListItemButton>
															</ListItem>
													</Fragment>
											)
											: (<Fragment key={i}>
													<Divider />
													<ListItem
															sx={{ padding: "0px 0px" }}
													>
															<ListItemButton
																	sx={{ padding: "4px 0px" }}
																	onClick={() => {
																			window.open(`https://api.whatsapp.com/send?phone=${item.number}`, '_self');
																			// window.open(`https://api.whatsapp.com/send?phone=+91${'9732777888'}&text=${"Hello, I am Satya i just want info about this car!"}`, '_self');
																	}}
															>
																	<ListItemAvatar>
																			<Avatar sx={{ background: "#4caf5024" }}>
																					<WhatsAppIcon sx={{ color: "#4caf50" }} />
																			</Avatar>
																	</ListItemAvatar>
																	<ListItemText primary={<Typography variant="subtitle2" color={config.colors.black}>
																			<b>{item.label}</b>
																	</Typography>} />
															</ListItemButton>
													</ListItem>
											</Fragment>)
							})
					}
					</List>
			</>
	)
}

const SendPrice = ({product = {}, handleModalClose = () => {}, ...rest}) => {
	const [message, setMessage] = useState();
	const [messageErr, setMessageErr] = useState();
	const [isLoading, setIsLoading] = useState(false);

	const handleSendQuotation = async () => {
			if(message == ' ') {
					setMessageErr(true);
			} else {
					setIsLoading(true);
					setMessageErr(false);
					
					const data = {
							message: message,
							product_id: product.id,
					};
					var token = localStorage.getItem('token');
					axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
					await axios.post(`${config.rootUrl}/${config.uris.sendQuotation}`, data)
					.then(({data}) => {
							if (data.status) {
									setIsLoading(false);
									alert(data.message);
									handleModalClose();

							} else {
									alert(data.message);
									setIsLoading(false);
							}
					})
					.catch(err => {
							console.error(err);
					});
			}
	}
	return (
		 <>
			<Typography id="modal-modal-title" variant="h6" component="h2">
					Send Your Price
			</Typography>
			<br />
			<textarea onChange={(e) => setMessage(e.target.value)} rows="5" className="my-textarea" placeholder="Write something ..."></textarea>
			{
					(messageErr) 
					&&
					<FormHelperText sx={{color: config.colors.red}} id="phone-helper">Please enter something!</FormHelperText>
			}
			{
					(isLoading) 
					?
					<LoadingButton loading variant="outlined">
							Send
					</LoadingButton>
					:
					<Button variant="contained" color="secondary"
							onClick={handleSendQuotation}
							sx={{ marginTop: "10px", width: "100%", color: "#fff", borderRadius: "50px", padding: "8px 22px", fontWeight: "600", textTransform: "capitalize" }}
					>Send
					</Button>
			}
			
		 </>
	);
} 
export default Search;
