const config = {
    // jwt_secret : 'kYMGuqXGmTSRSyYA3T198twPfyY5TKCbcrTJ6ZzT5UqXd0KHwXhePLYzz79PLNL8',
    
    rootUrl : `https://bnmotors.astergo.in/api/v1`,
    // rootUrl : `http://localhost:8000/api/v1`,

    
    uris: {
        IMAGE_URI: `https://bnmotors.astergo.in/image`,
        // IMAGE_URI: `http://localhost:8000/image`,
        PUBLIC_IMAGE_URI: `https://bnmotors.astergo.in/images`,
        // PUBLIC_IMAGE_URI: `http://localhost:8000/images`,
        LOGIN_URI : `login`,
        OTP_LOGIN_URI : `otp-login`,
        REGISTER_URI : `register`,
        TWO_FACTOR_AUTH : `2fa`,
        LOGOUT_URI : `auth/logout`,
        GET_AUTH_USER : `auth/user`,
        AUTH_USER_UPDATE : `auth/user/update`,
        getAllProducts : `product`,
        storeProduct: `auth/product/store`,
        getCategories: `auth/category`,
        sendQuotation: `auth/store-quotation`,
        homedata: `homedata`,
        triggreViews: `triggerViews`,
        search: `search`,
    },

    colors: {
        white: "#ffffff",
        red : "red",
        black : "#000",
        gray: "#696969",
        primary: {
            main: "#024284"
        },
        accent: {
            main: "#4ec5ef",
            light: "#4ec5ef12"
        }
    },
}
export default config;