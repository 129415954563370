import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import config from './core/config';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import {
  BrowserRouter as Router,
} from "react-router-dom";

import store from './store/index';
import { Provider } from 'react-redux';
import axios from 'axios';


const theme = createTheme({
  button: {
    height: 100
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          fontSize: '1rem',
        },
      },
      variants: [
        {
          props: {variant: "search-btn"},
          style: {
            height: "48px",
            background: config.colors.accent.main,
            color: "#fff"
          }
        }
      ]
    }
  },
  palette: {
    primary: {
      main: config.colors.primary.main,
      // light: config.colors.primary.light,
      // dark: config.colors.primary.dark,
    },
    secondary: {
      main: config.colors.accent.main,
      light: config.colors.accent.light,
      // dark: config.colors.secondary.dark,
      // contrastText: config.colors.secondary.contrastText,
    },
  },
});

const root = ReactDOM.createRoot(document.getElementById('root'));


let token = localStorage.getItem('token');
// const jwt_secret = config.jwt_secret;

const RenderApp = () => {
  return root.render(
    <Provider store={store}>
      <React.StrictMode>
        <ThemeProvider theme={theme}>
          <Router>
            <App />
          </Router>
        </ThemeProvider>
      </React.StrictMode>
    </Provider>
  );
}

if(token){
  axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  axios.get(`${config.rootUrl}/${config.uris.GET_AUTH_USER}`)
  .then(res => {
    store.dispatch({ type: 'SET_LOGIN', payload: res.data.data })
    RenderApp();
  }).catch( err => {
    console.error(err);
    localStorage.clear();
    RenderApp();
  })
} else {
  RenderApp();
  
}


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
