import { Box, Button, FormControl, FormHelperText, Grid, Input, InputLabel, MenuItem, Select, TextField, Typography, useScrollTrigger } from '@mui/material'
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import config from '../../core/config';
import AppLoader from '../../components/AppLoader';
import { useHistory } from 'react-router-dom';


const PostCar = () => {
    const [categories, setCategories] = useState([]);
    const [formData, setFormData] = useState({
        category_id: '',
        fuel_type: '',
        name: '',
        price: '',
        year: '',
        fitness: '',
        kilometre_drive: '',
        registration_authority: '',
        insurance: '',
        tax: '',
        vechile_location: '',
        rc_or_ca: '',
        hp: '',
        manufacture_date: '',
        owner: '',
        description: '',
        images: ''
    });
    const [formErrors, setFormErrors] = useState(formData);
    const [isLoading, setIsLoading] = useState(false);
    const history = useHistory();

    const handelInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    }

    const fetchCategories = async () => {
        setIsLoading(true);
        var token = localStorage.getItem('token');
        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        await axios.get(`${config.rootUrl}/${config.uris.getCategories}`)
            .then((res) => {
                if (res.data.status) {
                    setCategories(res.data.data)
                    setIsLoading(false);
                } else {
                    console.log(res);
                    setIsLoading(false);
                }
            }).catch(err => {
                console.error(err);
                setIsLoading(false);
            });
    }

    const handleSubmit = async (e) => {
        var fData = new FormData();
        e.preventDefault();
        setIsLoading(true);

        var token = localStorage.getItem('token');
        var myImages = [];
        var imagefile = document.querySelector("#postVehicleForm input[name='images[]']");
        for (var key in imagefile.files) {
            if (imagefile.files.hasOwnProperty(key)) {
                // myImages.push(imagefile.files[key]);
                fData.append("files[]", imagefile.files[key]);
            }
        }

        fData.append("category_id", formData.category_id)
        fData.append("fuel_type", formData.fuel_type)
        fData.append("name", formData.name)
        fData.append("price", formData.price)
        fData.append("year", formData.year)
        fData.append("fitness", formData.fitness)
        fData.append("kilometre_drive", formData.kilometre_drive)
        fData.append("registration_authority", formData.registration_authority)
        fData.append("insurance", formData.insurance)
        fData.append("tax", formData.tax)
        fData.append("vechile_location", formData.vechile_location)
        fData.append("rc_or_ca", formData.rc_or_ca)
        fData.append("hp", formData.hp)
        fData.append("manufacture_date", formData.manufacture_date)
        fData.append("owner", formData.owner)
        fData.append("description", formData.description)
        await axios.post(`${config.rootUrl}/${config.uris.storeProduct}`, fData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': `Bearer ${token}`
            }
        }
        ).then((res) => {
            if (res.data.status) {
                setIsLoading(false);
                alert(res.data.message);
                history.push('/');
            } else {
                // console.log(res.data.errors);
                res.data.errors.length != 0 && setFormErrors(res.data.errors);
                setIsLoading(false);
            }
        }).catch(err => {
            console.error(err);
            setIsLoading(false);
        });
    }

    useEffect(() => {
        fetchCategories();
    }, [])
    const handleFileChange = (e) => {
        setFormData({
            ...formData,
            images: e.target.files
        })
    }
    return (
        <div style={{ marginTop: "60px" }}>
            {
                <AppLoader visibal={isLoading} />
            }
            <div style={{ padding: "10px" }}>
                <Typography variant="h6">Post Vechicle</Typography>
                <Grid container justifyContent='center' spacing={1.5}>
                    <Grid item lg={8}>
                        <Box
                            sx={{
                                '& .MuiTextField-root': { margin: "5px 0px", width: '100%' },
                            }}
                            autoComplete="off"
                        >
                            <div>
                                <form onSubmit={handleSubmit} id="postVehicleForm">
                                    <Grid container justifyContent='center' spacing={2}>
                                        <Grid item lg={4}>
                                            <FormControl fullWidth sx={{marginTop:"5px"}}>
                                                <InputLabel id='demo-simple-select-helper-label'>Category</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-helper-label"
                                                    id="demo-simple-select-helper"
                                                    value=" "
                                                    label="Category"
                                                    onChange={handelInputChange}
                                                    name="category_id"
                                                    size='small'
                                                // error={formErrors.category_id !='' && true}
                                                >
                                                    <MenuItem value=" ">Select Category</MenuItem>
                                                    {
                                                        categories.length != 0 && categories.map((item, i) => {
                                                            return <MenuItem key={i} value={`${item.id}`}>{item.name}</MenuItem>
                                                        })
                                                    }
                                                </Select>
                                                <FormHelperText sx={{ color: "red", marginTop: "1px", marginBottom: "5px" }}>{formErrors.category_id != '' && formErrors.category_id}</FormHelperText>
                                            </FormControl>
                                            <FormControl fullWidth sx={{marginTop:"5px"}}>
                                                <InputLabel id='demo-simple-select'>Fuel Type</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select"
                                                    id="demo-simple-select-helper"
                                                    value=" "
                                                    label="Fuel Type"
                                                    onChange={handelInputChange}
                                                    name="fuel_type"
                                                    size='small'
                                                // error={formErrors.category_id !='' && true}
                                                >
                                                    <MenuItem value=" ">Select Fuel Type</MenuItem>
                                                    <MenuItem value="1">Petrol</MenuItem>
                                                    <MenuItem value="2">Diesel</MenuItem>
                                                    <MenuItem value="3">CNG</MenuItem>

                                                </Select>
                                                <FormHelperText sx={{ color: "red", marginTop: "1px", marginBottom: "5px" }}>{formErrors.fuel_type != '' && formErrors.fuel_type}</FormHelperText>
                                            </FormControl>
                                            <FormControl fullWidth>
                                                <TextField
                                                    name="name"
                                                    label="Vehicle Name"
                                                    variant="outlined"
                                                    size="small"
                                                    onChange={handelInputChange}
                                                // error={formErrors.name !='' && true}
                                                />
                                                <FormHelperText sx={{ color: "red", marginTop: "-3px", marginBottom: "5px" }}>{formErrors.name != '' && formErrors.name}</FormHelperText>
                                            </FormControl>
                                            <FormControl fullWidth>
                                                <TextField
                                                    name="price"
                                                    label="Vehicle Price"
                                                    variant="outlined"
                                                    size="small"
                                                    onChange={handelInputChange}
                                                // error={formErrors.price !='' && true} 
                                                />
                                                <FormHelperText sx={{ color: "red", marginTop: "-3px", marginBottom: "5px" }}>{formErrors.price != '' && formErrors.price}</FormHelperText>
                                            </FormControl>
                                            <FormControl fullWidth>
                                                <TextField
                                                    name="year"
                                                    label="Year"
                                                    variant="outlined"
                                                    size="small"
                                                    onChange={handelInputChange}
                                                // error={formErrors.year !='' && true} 
                                                />
                                                <FormHelperText sx={{ color: "red", marginTop: "-3px", marginBottom: "5px" }}>{formErrors.year != '' && formErrors.year}</FormHelperText>
                                            </FormControl>
                                            <FormControl fullWidth>
                                                <TextField
                                                    name="fitness"
                                                    label="Fitness"
                                                    variant="outlined"
                                                    size="small"
                                                    onChange={handelInputChange}
                                                // error={formErrors.fitness !='' && true}
                                                />
                                                <FormHelperText sx={{ color: "red", marginTop: "-3px", marginBottom: "5px" }}>{formErrors.fitness != '' && formErrors.fitness}</FormHelperText>
                                            </FormControl>
                                            <FormControl fullWidth>
                                                <TextField
                                                    name="kilometre_drive"
                                                    label="Kilometer Drive"
                                                    variant="outlined"
                                                    size="small"
                                                    onChange={handelInputChange}
                                                // error={formErrors.kilometre_drive !='' && true}
                                                />
                                                <FormHelperText sx={{ color: "red", marginTop: "-3px", marginBottom: "5px" }}>{formErrors.kilometre_drive != '' && formErrors.kilometre_drive}</FormHelperText>
                                            </FormControl>
                                        </Grid>
                                        <Grid item lg={4}>
                                            <FormControl fullWidth>
                                                <TextField
                                                    name="registration_authority"
                                                    label="Registration Authority"
                                                    variant="outlined"
                                                    size="small"
                                                    onChange={handelInputChange}
                                                // error={formErrors.registration_authority !='' && true} 
                                                />
                                                <FormHelperText sx={{ color: "red", marginTop: "-3px", marginBottom: "5px" }}>{formErrors.registration_authority != '' && formErrors.registration_authority}</FormHelperText>
                                            </FormControl>
                                            <FormControl fullWidth>
                                                <TextField
                                                    name="insurance"
                                                    label="Insurance"
                                                    variant="outlined"
                                                    size="small"
                                                    onChange={handelInputChange}
                                                // error={formErrors.insurance !='' && true} 
                                                />
                                                <FormHelperText sx={{ color: "red", marginTop: "-3px", marginBottom: "5px" }}>{formErrors.insurance != '' && formErrors.insurance}</FormHelperText>
                                            </FormControl>
                                            <FormControl fullWidth>
                                                <TextField
                                                    name="tax"
                                                    label="TAX"
                                                    variant="outlined"
                                                    size="small"
                                                    onChange={handelInputChange}
                                                // error={formErrors.tax !='' && true}
                                                />
                                                <FormHelperText sx={{ color: "red", marginTop: "-3px", marginBottom: "5px" }}>{formErrors.tax != '' && formErrors.tax}</FormHelperText>
                                            </FormControl>
                                            <FormControl fullWidth>
                                                <TextField
                                                    name="vechile_location"
                                                    label="Vehicle Location"
                                                    variant="outlined"
                                                    size="small"
                                                    onChange={handelInputChange}
                                                // error={formErrors.vechile_location !='' && true}
                                                />
                                                <FormHelperText sx={{ color: "red", marginTop: "-3px", marginBottom: "5px" }}>{formErrors.vechile_location != '' && formErrors.vechile_location}</FormHelperText>
                                            </FormControl>
                                            <FormControl fullWidth>
                                                <TextField
                                                    name="rc_or_ca"
                                                    label="RC/CA"
                                                    variant="outlined"
                                                    size="small"
                                                    onChange={handelInputChange}
                                                // error={formErrors.rc_or_ca !='' && true}
                                                />
                                                <FormHelperText sx={{ color: "red", marginTop: "-3px", marginBottom: "5px" }}>{formErrors.rc_or_ca != '' && formErrors.rc_or_ca}</FormHelperText>
                                            </FormControl>
                                            <FormControl fullWidth>
                                                <TextField
                                                    name="hp"
                                                    label="HP"
                                                    variant="outlined"
                                                    size="small"
                                                    onChange={handelInputChange}
                                                // error={formErrors.hp !='' && true}
                                                />
                                                <FormHelperText sx={{ color: "red", marginTop: "-3px", marginBottom: "5px" }}>{formErrors.hp != '' && formErrors.hp}</FormHelperText>
                                            </FormControl>
                                        </Grid>
                                        <Grid item lg={4}>
                                            <FormControl fullWidth>
                                                <TextField
                                                    name="manufacture_date"
                                                    label="Manufacture Date"
                                                    variant="outlined"
                                                    size="small"
                                                    onChange={handelInputChange}
                                                // error={formErrors.manufacture_date !='' && true}
                                                />
                                                <FormHelperText sx={{ color: "red", marginTop: "-3px", marginBottom: "5px" }}>{formErrors.manufacture_date != '' && formErrors.manufacture_date}</FormHelperText>
                                            </FormControl>
                                            <FormControl fullWidth>
                                                <TextField
                                                    name="owner"
                                                    label="Owner"
                                                    variant="outlined"
                                                    size="small"
                                                    onChange={handelInputChange}
                                                // error={formErrors.owner !='' && true}
                                                />
                                                <FormHelperText sx={{ color: "red", marginTop: "-3px", marginBottom: "5px" }}>{formErrors.owner != '' && formErrors.owner}</FormHelperText>
                                            </FormControl>
                                            <FormControl fullWidth>
                                                <TextField
                                                    name="description"
                                                    label="Description/Remark"
                                                    variant="outlined"
                                                    size="small"
                                                    onChange={handelInputChange}
                                                />
                                            </FormControl>
                                            <FormControl fullWidth sx={{ marginBottom: "10px" }}>
                                                <label className='img-label'>Upload Vehicle Image
                                                </label>
                                                <input
                                                    type="file"
                                                    name="images[]"
                                                    onChange={handleFileChange}
                                                    multiple
                                                    id="file"
                                                    class="custom-file-input"
                                                />
                                                <FormHelperText sx={{ color: "red", marginTop: "-3px", marginBottom: "5px" }}>{formErrors.files != '' && formErrors.files}</FormHelperText>
                                            </FormControl>
                                        </Grid>
                                        <Grid item lg={4}>
                                            <FormControl fullWidth>
                                                <Button type="submit" variant="contained" color="primary">Post</Button>
                                            </FormControl>
                                        </Grid>
                                    </Grid>



                                </form>
                                <br />
                                <br />
                                <br />
                            </div>
                        </Box>
                    </Grid>
                </Grid>
            </div>
        </div>
    )
}

export default PostCar
