import React from 'react';
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import config from '../../core/config';
import { Grid } from '@mui/material';

const CarCarousel = ({images = [], ...rest}) => {

    const options = {
        responsive: {
            0: {
                items: 1
            },
            600: {
                items: 3
            },
            1000: {
                items: 3
            }
        }
    };

    return (
        <Grid container spacing={0}>
            <Grid item lg={12} xs={12} sm={12} md={12}>
                <OwlCarousel
                    className="owl-theme"
                    autoplay={true}
                    items={1}
                    loop
                    margin={10}
                    nav={true}
                    dots={false}
                    {...options}
                >
                    {
                        images.length != 0 ?
                        images.map((item, i) => {
                            return (
                                <div key={i} className="item">
                                    <img src={`${config.uris.PUBLIC_IMAGE_URI}/${item.image}`} />
                                </div>
                            )
                        })
                        : <></>

                    }
                </OwlCarousel>
            </Grid>
        </Grid>
    )
}

export default CarCarousel;
